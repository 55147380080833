import React, {useMemo} from 'react';
import {
    PageDescription, PageLineItem, PageParagraph,
    PageSubTitle,
    PageTitle
} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";
import styled from "styled-components";

const StyledUnOrderedList = styled.ul`
    padding: 16px 0 24px 40px;
    margin: 0 0 0 0;
`;

export const Pagination = () => {
    const items = useMemo(() => {
        return [
            'Endpoints that are paginated include an optional query parameter in the request to specify the page to retrieve (named nextPage).',
            'Leave this query parameter out of the request to retrieve the first page of results.',
            'The response will contain an element named nextPage, populated with a unique key to the next page of data.',
            'To retrieve the next page of results, simply use the nextPage key returned in the response as the nextPage query parameter of your next request. Continue doing this until you receive no further results, which indicates that you’ve retrieved all of the available data.'
        ];
    }, []);

    const unorderedLines = useMemo(() => {
        return (
            <StyledUnOrderedList>
                {items.map((item, index) => {
                    return (
                        <PageLineItem key={index}>{item}</PageLineItem>
                    );
                })}
            </StyledUnOrderedList>
        )
    }, [items]);
    return (
        <div>
            <PageTitle>Pagination</PageTitle>
            <PageDescription>
                Many of the MarginEdge API endpoints paginate responses to limit the amount of data being returned from a single request.
                In order to obtain more data than is available in a single response, you can simply make another request for the next set or “page” of data.
                In order to make most efficient use of the APIs and limit the number of total API requests, please make use of any filters available on each endpoint so that you’re retrieving only the specific data needed at any given time.
            </PageDescription>
            <PageSubTitle>
                MarginEdge Public API
            </PageSubTitle>
            <PageParagraph>
                The MarginEdge public API uses cursor-based pagination and a fixed page size, which works as follows:
            </PageParagraph>
            {unorderedLines}
            <PageParagraph>
                Each page size is set to a fixed 100 records at this time.  If fewer than 100 records are available, you’ll retrieve all records with one request.  If more than 100 records are available, you’ll retrieve the first 100 with the first request and the next 100 (or up to 100) with the next request.
            </PageParagraph>
        </div>
    )
};