import React from "react";

import { RouteObject } from "react-router-dom";
import { Introduction } from "./pages/Introduction/Introduction";
import { AuthenticationProcess } from "./pages/AuthenticationProcess/AuthenticationProcess";
import { DeveloperGuide } from "./pages/DeveloperGuide/DeveloperGuide";
import { Api } from "./pages/Api/Api";
import {Environments} from "./pages/Environments/Environments";
import {ApiOverview} from "./pages/ApiOverview/ApiOverview";
import {Glossary} from "./pages/Glossary/Glossary";
import {Pagination} from "./pages/Pagination/Pagination";
import {RateLimits} from "./pages/RateLimits/RateLimits";
import {Errors} from "./pages/Errors/Errors";

export const navItems: NavItem[] = [
  {
    name: "Developer Guide",
    path: "/guide",
    element: <DeveloperGuide />,
  },
  {
    name: "Introduction",
    path: "/guide/introduction",
    element: <Introduction />,
  },
  {
    name: "Authentication Process",
    path: "/guide/authentication_process",
    element: <AuthenticationProcess />,
  },
  {
    name: "Environments",
    path: "/guide/environments",
    element: <Environments />,
  },
  {
    name: "API Overview",
    path: "/guide/api_overview",
    element: <ApiOverview />,
  },
  {
    name: "Glossary",
    path: "/guide/glossary",
    element: <Glossary />,
  },
  {
    name: "Pagination",
    path: "/guide/pagination",
    element: <Pagination />,
  },
  {
    name: "Rate Limits",
    path: "/guide/rate_limits",
    element: <RateLimits />,
  },
  {
    name: "Errors",
    path: "/guide/errors",
    element: <Errors />,
  },
  {
    name: "API Reference",
    path: "/api_reference",
    element: <Api />,
  },
];

type NavItem = {
  name?: string;
  path: string;
  element: JSX.Element;
  children?: RouteObject & NavItem[];
};

const navItemToRouteObject = (
  acc: RouteObject[],
  navItem: NavItem
): RouteObject[] => {
  const items = [
    ...acc,
    {
      path: navItem.path,
      element: navItem.element,
    },
  ];

  return (navItem.children || []).reduce(navItemToRouteObject, items);
};

export const routes = navItems.reduce(navItemToRouteObject, []);
