import React from 'react';
import {PageDescription, PageTitle} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";

export const Environments = () => {
    return (
        <div>
            <PageTitle>Environments</PageTitle>
            <PageDescription>
                The only currently available environment from which the APIs can be accessed is the MarginEdge production environment,
                which is the live MarginEdge environment customers are accessing.
                The base URL needed to access the APIs in this environment is <a href="https://api.marginedge.com/public">https://api.marginedge.com/public</a>.
            </PageDescription>
        </div>
    )
};