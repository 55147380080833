import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

const StyledSecondaryActive = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  color: #57595b;

  span {
    font-weight: 700 !important;
    font-family: "Avenir Next Bold", sans-serif;
  }

  &::before {
    content: "";
    width: 5px;
    height: 25px;
    background-color: #0072ce;
    position: absolute;
    left: 0;
  }
`;

const StyledSecondary = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  color: #57595b;

  a {
    text-decoration: none !important;
    color: #57595b !important;
  }
`;

type SecondaryNavItemProps = {
  title: string | JSX.Element;
  active?: boolean;
  path?: string;
  onClick?: () => void;
};

export const SecondaryNavItem = ({
  active,
  path,
  title,
  onClick
}: SecondaryNavItemProps) => {
  return (
    <ListItem style={{ margin: 0, padding: 0 }}>
      {active ? (
        <StyledSecondaryActive>
          <ListItemText primary={title} />
        </StyledSecondaryActive>
      ) : (
        <StyledSecondary>
          <Link to={!onClick && path} onClick={onClick}>
            <ListItemText primary={title} />
          </Link>
        </StyledSecondary>
      )}
    </ListItem>
  );
};
