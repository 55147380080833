import React from "react";
import { MethodSectionProps } from "../types";
import { MethodChip } from "components/MethodChip/MethodChip";

export const MethodSection: React.FC<MethodSectionProps> = ({ item }) => {
  return (
    <div>
      <MethodChip name={item.path} method={item.method} />
    </div>
  );
};
