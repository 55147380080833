import {List, ListItem, ListItemText} from "@mui/material";
import React, {useMemo, useState} from "react";
import {OpenAPIResponse} from "redoc/typings/types";
import {PortalSpecItem} from "types";
import {SubSectionHeader} from "./SubSectionHeader";
import styled from "styled-components";
import {CodeSamples} from "./CodeSamples";

const StyledListItem = styled(ListItem)`
    border-radius: 5px;
    width: 100%;
`;

const StyledResponseSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
`;

const StyledSuccessDiv = styled.div`
    border: 1px solid #B1E895;
    border-radius: 5px;
    margin-bottom: 2px;
`;

const StyledFailureDiv = styled.div`
    border: 1px solid #FFC9C9;
    border-radius: 5px;
    margin-bottom: 2px;
`;

const StyledSuccessResponse = styled(StyledListItem)`
    background-color: #e6ffed;
    cursor: pointer;
`;

const StyledFailResponse = styled(StyledListItem)`
    background-color: #fff1f0;
    cursor: pointer;
`;

type ResponsesSectionProps = {
    item: PortalSpecItem;
};

type ResponseGroups = {
    success: OpenAPIResponse[];
    fail: OpenAPIResponse[];
};

const isSuccess = (response: any) =>
    response != null &&
    parseInt(response.code, 10) >= 200 &&
    parseInt(response.code, 10) < 400;

export const ResponsesSection: React.FC<ResponsesSectionProps> = ({item}) => {
    const [selectedResponse, setSelectedResponse] =
        useState<OpenAPIResponse | null>(null);

    const handleResponseSelection = (response: OpenAPIResponse) => {
        if (selectedResponse == null) {
            return setSelectedResponse(response);
        }

        if ((response as any).code === (selectedResponse as any).code) {
            return setSelectedResponse(null);
        }

        setSelectedResponse(response);
    };

    const responses: ResponseGroups = useMemo(() => {
        return Object.keys(item.operation.responses).reduce(
            (acc, res) => {
                const response = item.operation.responses[res];
                (response as any).code = res; // set code for later user

                if (isSuccess(response)) {
                    acc.success.push(response);
                } else {
                    acc.fail.push(response);
                }

                return acc;
            },
            {
                success: [],
                fail: [],
            }
        ) as ResponseGroups;
    }, [item.operation.responses]);

    const RecursiveList = ({ jsonData }) => {
        function renderListItems(data) {
            return Object.keys(data).map((key, idx) => {
                if (Array.isArray(data[key])) {
                    return (
                        <>
                        <ListItem key={idx} style={{paddingBottom: "0px"}}>
                            <ListItemText primary={<>{key} <i style={{fontSize: "12px"}} className="fas fa-chevron-right" /></>} secondary={<>Array of objects</>}/>
                        </ListItem>
                            <div style={{paddingLeft: "80px", marginTop: "-30px"}}>
                                <List>
                                    {data[key].map((item, i) => (
                                        <RecursiveList key={i} jsonData={item} />
                                    ))}
                                </List>
                            </div>
                        </>
                    );
                } else {
                    return (
                        <ListItem key={idx}>
                            <ListItemText primary={key} secondary={<span>{data[key].description}</span>} />
                        </ListItem>
                    );
                }
            });
        }

        return <List>{renderListItems(jsonData)}</List>;
    };

    const definitionSample = useMemo(() => {
        if(selectedResponse == null) {
            return null;
        }
        const anyResponse = selectedResponse as any;
        return (
            <div>
                <SubSectionHeader title="Response Schema Definition"/>

                <RecursiveList jsonData={anyResponse.schemas[anyResponse.code]} />
            </div>
        );
    }, [selectedResponse]);

    const extractKeyAndTypePair = (jsonData) => {
        var newJsonData = {};
        for (var key in jsonData) {
            if (typeof(jsonData[key]) == "object") {
                if (Array.isArray(jsonData[key])) {
                    newJsonData[key] = jsonData[key].map(obj => extractKeyAndTypePair(obj));
                } else if (jsonData[key].hasOwnProperty('type')) {
                    newJsonData[key] = jsonData[key]["type"];
                } else {
                    newJsonData[key] = extractKeyAndTypePair(jsonData[key]);
                }
            } else {
                newJsonData[key] = jsonData[key];
            }
        }
        return newJsonData;
    }

    const responseSample = useMemo(() => {
        if (selectedResponse == null) {
            return null;
        }

        const anyResponse = selectedResponse as any;
        if (anyResponse.schemas[anyResponse.code]) {
            return (
                // <StyledListItem>
                    <CodeSamples
                        label="Response Schema Example"
                        type="json"
                        code={JSON.stringify(extractKeyAndTypePair(anyResponse.schemas[anyResponse.code]),
                            null,
                            2
                        )}
                    />
                // </StyledListItem>
            );
        }

        return null;
    }, [selectedResponse]);


    const successResponseSample = useMemo(() => {
        if(selectedResponse == null) {
            return null;
        }
        return (
            <StyledResponseSection>
                {definitionSample}
                {responseSample}
            </StyledResponseSection>
        )
    }, [selectedResponse]);

    return (
        <div>
            <SubSectionHeader title="Responses"/>

            <List>
                {responses.success.map((res, index) => {
                    return (
                        <StyledSuccessDiv key={index}>
                            <StyledSuccessResponse
                                key={res.description}
                                onClick={() => {
                                    handleResponseSelection(res);
                                }}
                            >
                                <ListItemText primary={res.description}/>
                            </StyledSuccessResponse>
                            {successResponseSample != null && successResponseSample}
                        </StyledSuccessDiv>
                    );
                })}

                {responses.fail.map((res) => {
                    return (
                        <StyledFailureDiv>
                            <StyledFailResponse
                                key={res.description}
                            >
                                <ListItemText primary={res.description}/>
                            </StyledFailResponse>
                        </StyledFailureDiv>
                    );
                })}

            </List>
        </div>
    );
};
