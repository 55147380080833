import React, { useEffect, useState } from "react";

import publicApiSpec from "./me-public-api.json";
import "./App.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SpecContext from "./context/SpecContext";
import styled from "styled-components";
import { Sidebar } from "./components/Sidebar/Sidebar";
import { convertToPortalSpec, parseSpec } from "./utils/openapi";
import { Header } from "components/Header/Header";

const StyledApp = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Avenir Next Medium", sans-serif !important;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding-top: 5rem;
  padding-left: 6rem;
  padding-right: 6rem;
`;

export const App = () => {
  const [spec, setSpec] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/guide/introduction");
    }
  }, [location, navigate]);

  useEffect(() => {
    const getSpec = async () => {
      const _spec = await parseSpec(publicApiSpec);
      setSpec(convertToPortalSpec(_spec));
    };

    getSpec();
  }, []);

  if (!spec) {
    return <p>Loading...</p>;
  }

  return (
    <SpecContext.Provider value={spec}>
      <StyledApp>
        <Sidebar />

        <StyledContent>
          <Header />

          <Outlet />
        </StyledContent>
      </StyledApp>
    </SpecContext.Provider>
  );
};
