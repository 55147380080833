import React from "react";
import styled from "styled-components";

const StyledHeaderSection = styled.h4`
  margin-bottom: 0;
`;

type SubSectionHeaderProps = {
  title: string;
};

export const SubSectionHeader: React.FC<SubSectionHeaderProps> = ({
  title,
}) => {
  return <StyledHeaderSection>{title}</StyledHeaderSection>;
};
