import { ApiHeader } from "api/sections/ApiHeader";
import { ResponsesSection } from "api/sections/ResponsesSection";
import { ApiContentProps } from "api/types";
import React from "react";
import { InputsSection } from "api/sections/InputsSection";


export const Orders: React.FC<ApiContentProps> = ({ item }) => {
    const codeSample = `curl \\
  https://api.marginedge.com/public/orders\?restaurantUnitId\=\${RESTAURANT_ID}&startDate\=\${START_DATE}&endDate\=\${END_DATE} \\
  -H "X-Api-Key: \${API_KEY}"`;

    return (
        <div>
            <ApiHeader item={item} />

            <p>Returns key fields for a paginated set of orders created (uploaded) within a specified date range and are currently in the specified status.</p>

            <p>{item.description}</p>

            <InputsSection codeSample={codeSample} item={item} />

            <ResponsesSection item={item} />
        </div>
    );
};
