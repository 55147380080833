import { ApiHeader } from "api/sections/ApiHeader";
import { InputsSection } from "api/sections/InputsSection";
import { ResponsesSection } from "api/sections/ResponsesSection";
import { ApiContentProps } from "api/types";
import React from "react";

export const Categories: React.FC<ApiContentProps> = ({ item }) => {
  const codeSample = `curl \\
  https://api.marginedge.com/public/categories\?restaurantUnitId\=\${RESTAURANT_ID} \\
  -H "X-Api-Key: \${API_KEY}"`;

  const responseSchema = JSON.stringify(
    (item.operation.responses["200"] as any).schema,
    null,
    2
  );

  return (
    <div>
      <ApiHeader item={item} />

      <p>Returns a paginated set of categories for the specified restaurant unit.</p>

      <p>{item.description}</p>

      <InputsSection item={item} codeSample={codeSample} />

      <ResponsesSection item={item} />
    </div>
  );
};
