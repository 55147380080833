import React, {useMemo} from 'react';
import {tableContents} from "./tableContents";
import '../../assets/css/table.css';
import {PageDescription, PageTitle} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";

export const Glossary = () => {
    const data = useMemo(() => {
        return tableContents.map((content) => {
            return {
                ...content,
                definition: content.definition.replace(/<pre>/g, '<pre class="inline-pre">').replace(/<code>/g, '<code class="inline-code">')
            };
        });
    }, []);

    const tableRows = useMemo(() => {
        return data.map((dt, index) => {
            return (
                <tr key={index}>
                    <td>{dt.term}</td>
                    <td dangerouslySetInnerHTML={{__html: dt.definition}}></td>
                </tr>
            );
        });
    }, [data]);
    return (
        <div>
            <PageTitle>Glossary</PageTitle>
            <PageDescription>We’ve defined below common terms that you may come across in our API documentation.</PageDescription>
            <div style={{paddingTop: "12px"}}></div>
            <table>
                <tbody>
                    <tr>
                        <th>Term</th>
                        <th>Definition</th>
                    </tr>
                    {tableRows}
                </tbody>
            </table>
        </div>
    );
};
