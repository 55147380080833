import React from "react";
import {PageDescription, PageTitle} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";
export const RateLimits = () => {
    return (
        <div>
            <PageTitle>Rate Limits</PageTitle>
            <PageDescription>
                The MarginEdge public APIs are rate limited to 1 request per second per API key across all endpoints.
                Please ensure that as you’re developing your integration you limit the number of total requests per second to any endpoint to 1.
            </PageDescription>
        </div>
    )
};