import React from 'react';
import styled from "styled-components";
import { HeadersSection } from './HeadersSection';
import { ParamsSection } from './ParamsSection';
import { CodeSamples } from './CodeSamples';
import { PortalSpecItem } from 'types';
import {PathVariableSection} from "./PathVariableSection";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledInputs = styled.div`
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface InputsSectionProps {
  item: PortalSpecItem;
  codeSample?: string;
}

export const InputsSection: React.FC<InputsSectionProps> = ({item, codeSample}): JSX.Element => {
  return <StyledLayout>
    <StyledInputs>
      <HeadersSection item={item} />

      <ParamsSection item={item} />

      <PathVariableSection item={item} />
    </StyledInputs>

    {codeSample && <CodeSamples code={codeSample} label="Examples" type="bash" />}
  </StyledLayout>;
}