import { Button } from "@mui/material";
import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import styled from "styled-components";

type CodeSamplesProps = {
  code?: string;
  type?: string;
  label?: string;
};

const StyledSampleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledCopyIcon = styled.i`
  margin-right: 0.5rem;
`;

const StyledSamples = styled.div`
  margin-left: 0.5rem;
  min-width: 60%;
  max-width: 75%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledCodeSamples = styled(SyntaxHighlighter)`
  min-height: 200px;
  line-height: 2;
`;

const codeStyleTheme =
  require("react-syntax-highlighter/dist/esm/styles/hljs/androidstudio").default;

export const CodeSamples: React.FC<CodeSamplesProps> = ({
  code,
  type,
  label,
}) => {
  return (
    <StyledSamples>
      {code && (
        <>
          <StyledSampleHeader>
            {label && <h4>{label}</h4>}
            <Button
              variant="outlined"
              onClick={() => {
                navigator.clipboard.writeText(code);
              }}
            >
              <StyledCopyIcon className="fas fa-clipboard" /> Copy
            </Button>
          </StyledSampleHeader>

          <StyledCodeSamples
            language={type || "bash"}
            showLineNumbers
            style={codeStyleTheme}
          >
            {code}
          </StyledCodeSamples>
        </>
      )}
    </StyledSamples>
  );
};
