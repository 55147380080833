import React, {useContext, useEffect, useMemo, useState} from "react";

import styled from "styled-components";
import logo from "../../assets/logo.png";
import { List } from "@mui/material";
import { PrimaryNavItem } from "./PrimaryNavItem";
import { SecondaryNavItem } from "./SecondaryNavItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SpecContext from "../../context/SpecContext";

const StyledSidebar = styled.div`
  min-width: 320px;
  max-width: 320px;
  background-color: #e6e6e6;
  overflow: auto;
  display: flex;
  flex-direction: column;

  backface-visibility: hidden;

  height: 100vh;
  position: sticky;
  position: -webkit-sticky;
  top: 0;

  @media print {
    display: none;
  }
`;

const StyledLogo = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #636567;
  padding: 20px 10px;

  img {
    width: 75%;
  }

  h3 {
    margin: 0;
    padding: 0;
  }
`;

enum NavItemType {
  PAGE,
  API,
}

export const Sidebar = function () {
  const { pathname: navPath } = useLocation();
  const spec = useContext(SpecContext);
  const navigate = useNavigate();
  const [activeApiReferencePath, setActiveApiReferencePath] = useState<string>('/api_reference/GET-/categories');

  const handleScroll = () => {
    const sections = ["/api_reference/GET-/categories", "/api_reference/GET-/orders", "/api_reference/GET-/orders/{orderId}", "/api_reference/GET-/products", "/api_reference/GET-/restaurantUnits", "/api_reference/GET-/vendors", "/api_reference/GET-/vendors/{vendorId}/vendorItems", "/api_reference/GET-/vendors/{vendorId}/vendorItems/{vendorItemCode}/packaging"];
    let newActiveId = "";
    for (const section of sections) {
      const element = document.getElementById(section);
      if (element && element.getBoundingClientRect().top <= window.innerHeight / 2) {
        newActiveId = section;
      }
    }
    setActiveApiReferencePath(newActiveId);
  };

  useEffect(() => {
    if(navPath.startsWith('/api_reference')) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const nav = useMemo(
    () => [
      {
        name: "Developer Guide",
        path: "/guide",
        type: NavItemType.PAGE,
        children: [
          {
            name: "Introduction",
            path: "/guide/introduction",
            type: NavItemType.PAGE,
          },
          {
            name: "Authentication Process",
            path: "/guide/authentication_process",
            type: NavItemType.PAGE,
          },
          {
            name: "Environments",
            path: "/guide/environments",
            type: NavItemType.PAGE,
          },
          {
            name: "API Overview",
            path: "/guide/api_overview",
            type: NavItemType.PAGE,
          },
          {
            name: "Glossary",
            path: "/guide/glossary",
            type: NavItemType.PAGE,
          },
          {
            name: "Pagination",
            path: "/guide/pagination",
            type: NavItemType.PAGE,
          },
          {
            name: "Rate Limits",
            path: "/guide/rate_limits",
            type: NavItemType.PAGE,
          },
          {
            name: "Errors",
            path: "/guide/errors",
            type: NavItemType.PAGE,
          },
        ],
      },
      {
        name: "API Reference",
        path: "/api_reference",
        type: NavItemType.PAGE,
        children: spec.items.map((item) => ({
          item,
          type: NavItemType.API,
        })),
      },
    ],
    [spec.items]
  );

  const navContent = useMemo(() => {
    return nav
      .map((item) => {
        const active = navPath.startsWith(item.path);
        const items = [
          <PrimaryNavItem
            key={`primary-${item.path}`}
            active={active}
            title={item.name}
            path={item.path}
          />,
        ];

        if (active) {
          items.push(
            <List
              style={{ margin: 0, padding: 0 }}
              key={`secondary-${item.path}`}
            >
              {item.children?.map((child: any) => {
                if (child.type === NavItemType.API) {
                  return (
                    <SecondaryNavItem
                      key={`secondary-${item.path}/${child.item.path}`}
                      title={child.item.operation.operationId}
                      active={activeApiReferencePath.endsWith(`${child.item.method}-${child.item.path}`)}
                      onClick={() => {
                        const sectionId = `${item.path}/${child.item.method}-${child.item.path}`;

                        navigate(sectionId);
                        setActiveApiReferencePath(sectionId);
                        document
                          .getElementById(sectionId)
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                    />
                  );
                }
                setActiveApiReferencePath('/api_reference/GET-/categories');
                return (
                  <SecondaryNavItem
                    key={`secondary-${child.path}`}
                    title={child.name}
                    active={navPath.startsWith(child.path)}
                    path={child.path}
                  />
                );
              })}
            </List>
          );
        }

        return items;
      })
      .flat();
  }, [navPath, nav, navigate, activeApiReferencePath]);

  return (
    <StyledSidebar>
      <h3>
        <StyledLogo to="/" style={{ textDecoration: "none" }}>
          <img src={logo} alt="MarginEdge Developer Portal" />

          <span>API</span>
        </StyledLogo>
      </h3>

      {navContent}
    </StyledSidebar>
  );
};
