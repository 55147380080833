import Chip from "@mui/material/Chip";
import React from "react";
import styled from "styled-components";

type MethodChipProps = {
  method: string;
  name: string;
};

const StyledContainer = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  margin-right: 0.5rem;
    height: 24px;
    font-size: 12px;
`;

const StyledName = styled.span`
  overflow-wrap: anywhere;
`;

export const MethodChip: React.FC<MethodChipProps> = ({ method, name }) => {
  const color = method === "GET" ? "success" : "error";

  return (
    <StyledContainer>
      <StyledChip color={color} label={method} />
      <StyledName>{name}</StyledName>
    </StyledContainer>
  );
};
