import { ApiContent } from "api/ApiContent";
import SpecContext from "context/SpecContext";
import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import postmanCollection from "../../marginedge-postman-collection.json";

import { PortalSpec } from "types";
import { Button } from "@mui/material";

const StyledApiContentWrapper = styled.div`
  overflow: hidden;
  contain: layout;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-right: 1rem;
  }
`;

const StyledIcon = styled.i`
  margin-right: 0.5rem;
`;


export const Api = () => {
  const spec: PortalSpec = useContext(SpecContext);

  useEffect(() => {
    // scroll to section on page for the user
    const sectionId = document.location.hash.replace("#", "");

    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const postmanCollectionDownload = useMemo(() =>  {
    return URL.createObjectURL(new Blob([JSON.stringify(postmanCollection)], { type: "application/json" }))
  }, []);

  return (
    <StyledApiContentWrapper>
      <StyledHeader>
        <h2>API Reference</h2>
        <Button
          LinkComponent="a"
          download="marginedge-postman-collection.json"
          variant="text"
          target="_blank"
          rel="noreferrer"
          href={postmanCollectionDownload}>
            (&nbsp;<StyledIcon className="fas fa-download" />Postman Collection&nbsp;)
        </Button>
      </StyledHeader>

      {spec.items.map((item) => (
        <ApiContent key={`${item.method} ${item.path}`} item={item} />
      ))}
    </StyledApiContentWrapper>
  );
};
