import { List, ListItem, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { OpenAPIParameter } from "redoc/typings/types";
import { PortalSpecItem } from "types";
import { ParamSecondaryDetails } from "./ParamSecondaryDetails";
import { SubSectionHeader } from "./SubSectionHeader";

type HeadersSectionProps = {
  item: PortalSpecItem;
};

export const ParamsSection: React.FC<HeadersSectionProps> = ({ item }) => {
  const params: OpenAPIParameter[] = useMemo(() => {
    return item.operation.parameters.filter(
      (param) => (param as any).in === "query"
    ) as OpenAPIParameter[];
  }, [item.operation.parameters]);

  if (!params.length) {
    return null;
  }

  return (
    <div style={{marginTop: "-25px"}}>
      <SubSectionHeader title="Query Params" />

      <List>
        {params.map((query) => {
          return (
            <ListItem key={`query-${query.name}`}>
              <ListItemText
                primary={query.name}
                secondary={<ParamSecondaryDetails param={query} />}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
