import styled from "styled-components";

export const PageTitle = styled.h1`
    font: 48px "Avenir Next";
    font-weight: bold;
    margin: 0 0 0 0;
    padding: 0 0 24px 0;
`;

export const PageDescription = styled.p`
    font: 24px "Avenir Next";
    margin: 0 0 0 0;
    padding: 12px 0 12px 0;
`;

export const PageParagraph = styled.p`
    font: 18px "Avenir Next";
    margin: 0 0 0 0;
    padding: 12px 0 0 0;
`;

export const PageLineItem = styled.li`
    font: 18px "Avenir Next";
    margin: 0 0 0 0;
    padding: 6px 0 6px 0;
`;

export const PageSubTitle = styled.h2`
    font: 24px "Avenir Next";
    font-weight: bold;
    margin: 24px 0 -4px 0;
`;

export const PageImage = styled.img`
    margin: 16px 0 42px 0;
`;