import React from "react";

import styled from "styled-components";
import Button from "@mui/material/Button";

const HeaderNav = styled.div<{ open?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  z-index: 1000;
`;

const StyledQuestionIcon = styled.i`
  display: inline-block;
  width: 16px;
  height: 16px;
  font-weight: bold;
  padding: 5px;
  margin-right: .5rem;
  border-radius: 50%;
  background-color: #1976d2;
  color: #F7F7F7;
  text-align: center;
`;

export const Header = function () {
  return (
    <HeaderNav>
      <Button component="a" href="https://help.marginedge.com/" variant="text" target="_blank">
        <StyledQuestionIcon className="fas fa-question" />
        Help
      </Button>
    </HeaderNav>
  );
};
