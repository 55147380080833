export const tableContents = [
    {
        responseCode: '400',
        name: 'Bad Request',
        description: 'This error occurs typically when a request is structured or formatted incorrectly, or there’s a syntax error in the request.  If you receive a 400 response code, please evaluate the request that you’re sending to ensure it conforms to the API specification.'
    },
    {
        responseCode: '403',
        name: 'Forbidden',
        description: 'This error indicates that the request was understood but that the server refused to honor the request.  It may occur, for example, if your API key does not have access to the requested data.  To troubleshoot, do not repeat the same request again but evaluate the request you’re making to ensure that it’s within the scope of access that you have.'
    },
    {
        responseCode: '404',
        name: 'Not Found',
        description: 'This error indicates that the requested resource was unavailable and couldn’t be found.  Check the specific host name or endpoint you’re attempting to access to ensure you’re making a proper request for the correct resource.'
    },
    {
        responseCode: '500',
        name: 'Internal Server Error',
        description: 'This error occurs when MarginEdge was unable to fulfill a request due to an internal error. Try the request again, and if it repeatedly fails with a 500 error after a reasonable period of time has elapsed, contact <a href="mailto:help@marginedge.com">help@marginedge.com</a> for assistance.'
    },
]