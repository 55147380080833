import React, {useMemo} from 'react';
import {tableContents} from "./tableContents";
import {PageDescription, PageTitle} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";

export const Errors = () => {
    const data = tableContents;
    const tableRows = useMemo(() => {
        return data.map((dt, index) => {
            return (
                <tr key={index}>
                    <td>{dt.responseCode}</td>
                    <td>{dt.name}</td>
                    <td dangerouslySetInnerHTML={{ __html: dt.description }}></td>
                </tr>
            );
        });
    }, [data]);
    return (
        <div>
            <PageTitle>Errors</PageTitle>
            <PageDescription>
                The HTTP response code for a successful request to the MarginEdge public API is 200.
                Unsuccessful requests will result in one of the following HTTP response codes:
            </PageDescription>
            <table>
                <tbody>
                    <tr>
                        <th>HTTP Response Code</th>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                    {tableRows}
                </tbody>
            </table>
        </div>
    )
};