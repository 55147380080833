import React from "react";

import { Categories } from "./Categories/Categories";
import { ApiContentProps } from "./types";
import { ApiHeader } from "./sections/ApiHeader";
import { ResponsesSection } from "./sections/ResponsesSection";
import {Orders} from "./Orders/Orders";
import {SingleOrder} from "./Orders/SingleOrder";
import {Products} from "./Products/Products";
import {RestaurantUnits} from "./RestaurantUnit/RestaurantUnits";
import {Vendors} from "./Vendors/Vendors";
import {VendorItems} from "./Vendors/VendorItems";
import {VendorItemsPackaging} from "./Vendors/VendorItemsPackaging";
import { InputsSection } from "./sections/InputsSection";

const ApiSections = {
  "GET /categories": Categories,
  "GET /orders": Orders,
  "GET /orders/{orderId}": SingleOrder,
  "GET /products": Products,
  "GET /restaurantUnits": RestaurantUnits,
  "GET /vendors": Vendors,
  "GET /vendors/{vendorId}/vendorItems": VendorItems,
  "GET /vendors/{vendorId}/vendorItems/{vendorItemCode}/packaging": VendorItemsPackaging
};

export const ApiContent: React.FC<ApiContentProps> = ({ item }) => {
  const API = ApiSections[`${item.method} ${item.path}`];

  if (API) {
    return <API item={item} />;
  }

  return (
    <div>
      <ApiHeader item={item} />

      <InputsSection item={item} />

      <ResponsesSection item={item} />
    </div>
  );
};
