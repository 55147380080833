export const tableContents = [
    {
        name: 'Get available restaurants',
        endPoint: 'GET <pre><code>/restaurantUnits</code></pre>',
        description: 'Returns the name and identifier for each restaurant unit the provided API key has access to.'
    },
    {
        name: 'Get orders by created date and status',
        endPoint: 'GET <pre><code>/orders</code></pre>',
        description: 'Returns key fields for a paginated set of orders created (uploaded) within a specified date range and are currently in the specified status.'
    },
    {
        name: 'Get order detail',
        endPoint: 'GET <pre><code>/orders/:orderId</code></pre>',
        description: 'Returns detailed information for an individual order.'
    },
    {
        name: 'Get products',
        endPoint: 'GET <pre><code>/products</code></pre>',
        description: 'Returns a paginated set of products for the specified restaurant unit.'
    },
    {
        name: 'Get categories',
        endPoint: 'GET <pre><code>/categories</code></pre>',
        description: 'Returns a paginated set of categories for the specified restaurant unit.'
    },
    {
        name: 'Get vendors',
        endPoint: 'GET <pre><code>/vendors</code></pre>',
        description: 'Returns a paginated list of vendors used by the specified restaurant unit.'
    },
    {
        name: 'Get vendor items',
        endPoint: 'GET <pre><code>/vendors/:vendorId/vendorItems</code></pre>',
        description: 'Returns a paginated list of vendor items used by the specified restaurant unit, limited to the specified vendor.'
    },
    {
        name: 'Get vendor item packaging',
        endPoint: 'GET <pre><code>/vendors/:vendorId/vendorItems/:vendorItemCode/packaging</code></pre>',
        description: 'Returns a paginated list of packaging options for the specified vendor item.'
    },
];