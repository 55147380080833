import React from "react";
import authImage from "../../assets/authenticationProcess.png";
import userSettingsImage from "../../assets/userSettings.png";
import generateApiKey from "../../assets/generateApiKey.png";
import yourApiKeyIsReady from "../../assets/youApiKeyIsReady.png";
import {
    PageDescription, PageImage, PageLineItem, PageParagraph,
    PageSubTitle,
    PageTitle
} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";
import styled from "styled-components";

const StyledOrderedList = styled.ol`
    padding: 16px 0 24px 40px;
    margin: 0 0 0 0;
`;
export const AuthenticationProcess = () => {
    return (
        <div>
            <PageTitle>Authentication Process</PageTitle>
            <PageDescription>
                The authorization model for the MarginEdge public APIs relies on an API key that is passed in the
                request header of each API call.
                An API key is issued to a user in MarginEdge and is directly tied to that user’s scope of access as a
                MarginEdge Admin.
                In other words, the API key grants access to the restaurants for which the user who generated the key
                has a MarginEdge Admin role and which have been enabled for access to the MarginEdge public API.
            </PageDescription>
             {/*12px + 24px = 36px */}
            <PageSubTitle>API key access</PageSubTitle>
            <PageParagraph>
                Each MarginEdge Admin with access to a restaurant that has been enabled for access to the public APIs
                has the ability to generate a single API key.
                If you have access in MarginEdge to the restaurant for which you’re developing the integration but are
                not a MarginEdge Admin, you have two options:
            </PageParagraph>
            <StyledOrderedList>
                <PageLineItem>
                    <div>Contact a MarginEdge Admin and ask for your role to be upgraded to MarginEdge Admin.</div>
                </PageLineItem>
                <PageLineItem>
                    <div>Contact a MarginEdge Admin and ask them to generate the API key that you need to access the API.</div>
                </PageLineItem>
            </StyledOrderedList>
            <PageSubTitle>Generating an API key</PageSubTitle>
            <PageParagraph>To generate an API key:</PageParagraph>
            <StyledOrderedList>
                <PageLineItem>
                    <div>Head to <a href="https://app.marginedge.com">MarginEdge</a> and log in.</div>
                </PageLineItem>
                <PageLineItem>
                    <div>Click your name in the upper right corner of the web app and select Settings.</div>
                    <br />
                    <PageImage style={{width: "600px"}} src={authImage} alt="Authentication Process"/>
                </PageLineItem>
                <PageLineItem>
                    <div>If your restaurant is enabled for access to the public APIs and you are a MarginEdge Admin for the
                    restaurant, you’ll see a tab on the Settings page labeled Security.
                    Navigate to that tab. If you don’t see the Security tab, chances are that you are not a MarginEdge
                    Admin or the restaurant(s) on which you are a MarginEdge Admin are not enabled for the public APIs.
                    If it’s the former, contact a MarginEdge Admin for the restaurant to grant the access you’ll need or
                    generate the API key.
                    If it’s the latter, our Support Team can help - just send an email to <a
                    href="mailto:help@marginedge.com">help@marginedge.com</a>.</div>
                    <br />
                    <PageImage style={{width: "585px"}} src={userSettingsImage} alt="User Settings"/>
                </PageLineItem>
                {/*    PI = 42px + PL = 6px = 48 px gap*/}
                <PageLineItem>
                    <div>Click “Create new API key”</div>
                </PageLineItem>
                <PageLineItem>
                    <div>In the window that pops up that allows you to generate your API key, enter a name and description.
                    Please enter a name and description that accurately describes the intended purpose of this API key.
                    Then click “Save”.</div>
                    <br />
                    <PageImage style={{width: "585px"}}  src={generateApiKey} alt="Generate Api Key"/>
                </PageLineItem>
                <PageLineItem>
                    <div>Your API key will be displayed in the next popup window.
                    This is the only opportunity you’ll have to access and copy your API key.
                    Copy it here and store it securely.</div>
                    <br />
                    <PageImage style={{width: "585px"}}  src={yourApiKeyIsReady} alt="Your Api Key is Ready"/>
                </PageLineItem>
            </StyledOrderedList>
        </div>
    );
};
