export const tableContents = [
    {
        term: 'API Key',
        definition: 'An API key is used to identify and authenticate a user for access to the MarginEdge public API. Think of it as and treat it like you would a password.'
    },
    {
        term: 'Category-Level Invoice',
        definition: 'A category-level invoice contains no line item detail. In the GET <pre><code>/orders/:orderId</code></pre> (get order detail) endpoint for category-level invoices, each line item in the response represents a category.  For such invoices, the response from this endpoint will contain line items with a categoryId and linePrice but won’t have other details recorded for invoices processed with line item level detail, such as vendorItemCode, vendorItemName, quantity, and unitPrice.  Category-level invoices are frequently used for expenses like rent, utilities, marketing bills, and janitorial services, where line item detail is not needed. ' +
            '<br/><br/>More information on category-level invoices can be found here:  <a href="https://help.marginedge.com/hc/en-us/articles/8151891508243-Manual-Category-Level-Invoices-">https://help.marginedge.com/hc/en-us/articles/8151891508243-Manual-Category-Level-Invoices-</a> '
    },
    {
        term: 'Central Product ID',
        definition: 'Each company concept in MarginEdge shares a single product database. ' +
            'While naming of products can be customized to the company concept, many of the products that are created are linked back to a central set of products maintained by MarginEdge. ' +
            'This identifier, if present, represents the relationship to a centralized definition of a given product. ' +
            'It may be useful to look at trends across restaurants if you are working with restaurants that span multiple companies or concepts.'
    },
    {
        term: 'Company Concept Product ID',
        definition: 'The company concept product ID is the unique identifier for a given product purchased and used by your restaurant. ' +
            'Each company concept in MarginEdge shares a single product database.'
    },
    {
        term: 'Input Tax Credits',
        definition: 'Input tax credits are a form of federal tax paid on purchases levied by other governments outside the United States.  Input tax credits are found on invoices from Canadian restaurants, and are known in Canada as GST (Goods & Services Tax) or HST (Harmonized Sales Tax).'
    },
    {
        term: 'Invoice Status',
        definition: 'The invoice status is the processing stage in the MarginEdge invoice processing workflow that an invoice is in. ' +
            'Values of the invoice status in the API can be any of the following: ' +
            '<ul>' +
            '<li>PREPROCESSING</li>' +
            '<li>EDI_PENDING</li>' +
            '<li>IMAGE_PENDING</li>' +
            '<li>INITIAL_REVIEW</li>' +
            '<li>RECONCILIATION</li>' +
            '<li>FINAL_REVIEW</li>' +
            '<li>AM_REVIEW</li>' +
            '<li>PENDING_APPROVAL</li>' +
            '<li>CLOSED</li>' +
            '</ul>' +
            'This help article provides more information about each individual status:  <a href="https://help.marginedge.com/hc/en-us/articles/218399877-Invoice-FAQs">https://help.marginedge.com/hc/en-us/articles/218399877-Invoice-FAQs</a>'
    },
    {
        term: 'Restaurant Unit ID',
        definition: 'The restaurant unit ID is a unique identifier of an individual restaurant in MarginEdge. ' +
            'All endpoints (with the exception of GET <pre><code>/restaurantUnits</code></pre>) require that the identifier of a restaurant unit in MarginEdge be provided with the request. ' +
            'The GET <pre><code>/restaurantUnits</code></pre> endpoint will return all accessible restaurants with their unique identifiers (i.e., restaurantUnitId). ' +
            'These should be used to make requests to any of the other endpoints.'
    }
];