import React, { useMemo, useState } from "react";
import { ApiContentProps } from "../types";
import { MethodSection } from "./MethodSection";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledHeader = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    a {
      cursor: pointer;
    }
  }
`;

const StyledSubHeader = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
  color: #5a5d60;
  &:hover {
    a {
      cursor: pointer;
    }
  }
`;

const StyledLink = styled.a`
  margin-left: 0.5rem;
  color: rgb(87, 89, 91);
`;

export const ApiHeader: React.FC<ApiContentProps> = ({ item }) => {
  const [showLink, setShowLink] = useState(false);
  const navigate = useNavigate();

  const handleMouseOver = () => {
    setShowLink(true);
  };

  const itemPath = useMemo(
    () => `/api_reference/${item.method}-${item.path}`,
    [item.method, item.path]
  );

  const fullItemUrl = useMemo(
    () =>
      `${document.location.protocol}//${document.location.host}/#${itemPath}`,
    [itemPath]
  );

  return (
      <>
        <StyledHeader
          id={`/api_reference/${item.method}-${item.path}`}
          onMouseEnter={handleMouseOver}
          onMouseLeave={() => setShowLink(false)}
        >
          {item.operation.operationId}
        </StyledHeader>

        <StyledSubHeader
            id={`/api_reference/${item.method}-${item.path}`}
            onMouseEnter={handleMouseOver}
            onMouseLeave={() => setShowLink(false)}
        >
          <MethodSection item={item} operation={item.operation} />

          {showLink && (
              <StyledLink
                  onClick={() => {
                    navigator.clipboard.writeText(fullItemUrl);
                    navigate(itemPath);
                  }}
              >
                <i className="fa-solid fa-link" />
              </StyledLink>
          )}
        </StyledSubHeader>
      </>
  );
};
