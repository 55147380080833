import { ApiHeader } from "api/sections/ApiHeader";
import { ResponsesSection } from "api/sections/ResponsesSection";
import { ApiContentProps } from "api/types";
import React from "react";
import { InputsSection } from "api/sections/InputsSection";

export const Vendors: React.FC<ApiContentProps> = ({ item }) => {
    const codeSample = `curl \\
  https://api.marginedge.com/public/vendors\?restaurantUnitId\=\${RESTAURANT_ID} \\
  -H "X-Api-Key: \${API_KEY}"`;

    return (
        <div>
            <ApiHeader item={item} />

            <p>Returns a paginated list of vendors used by the specified restaurant unit.</p>

            <p>{item.description}</p>

            <InputsSection item={item} codeSample={codeSample} />

            <ResponsesSection item={item} />
        </div>
    );
};
