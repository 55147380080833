import {PortalSpecItem} from "../../types";
import {OpenAPIParameter} from "redoc/typings/types";
import React, {useMemo} from "react";
import {SubSectionHeader} from "./SubSectionHeader";
import {List, ListItem, ListItemText} from "@mui/material";
import {ParamSecondaryDetails} from "./ParamSecondaryDetails";

type HeadersSectionProps = {
    item: PortalSpecItem;
};

export const PathVariableSection: React.FC<HeadersSectionProps> = ({item}) => {
    const params: OpenAPIParameter[] = useMemo(() => {
        return item.operation.parameters.filter(
            // path variables get auto included as they are mentioned in the path despite mentioning them in requestParameters
            (param) => (param as any).in === "path" && (param as any).hasOwnProperty("description")
        ) as OpenAPIParameter[];
    }, [item.operation.parameters]);

    if (!params.length) {
        return null;
    }

    return (
        <div>
            <SubSectionHeader title="Path Variables" />

            <List>
                {params.map((path) => {
                    return (
                        <ListItem key={path.name}>
                            <ListItemText
                                primary={path.name}
                                secondary={<ParamSecondaryDetails param={path} />}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};