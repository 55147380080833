import { loadAndBundleSpec } from "redoc";
import { OpenAPIComponents, OpenAPISpec } from "redoc/typings/types";
import { PortalSpec, PortalSpecItem } from "types";

export const parseSpec = async (spec: object): Promise<OpenAPISpec> => {
  return await loadAndBundleSpec(spec);
};

const METHODS = [
  "get",
  "put",
  "post",
  "delete",
  "options",
  "head",
  "patch",
  "trace",
];
export const DEFAULT_RESPONSE_TYPE = "application/json";
export const convertToPortalSpec = (spec: OpenAPISpec): PortalSpec => {
  const items = Object.keys(spec.paths).reduce(
    (acc, path): PortalSpecItem[] => {
      const item = spec.paths[path];

      METHODS.forEach((method) => {
        if (item[method]) {
          const responses = item[method].responses;
          Object.keys(responses).forEach((code) => {
            const response = responses[code];
            if (!response.schemas) {
              response.schemas = {};
            }

            if (
              response.content?.hasOwnProperty(DEFAULT_RESPONSE_TYPE) &&
              response.content[DEFAULT_RESPONSE_TYPE]?.schema.hasOwnProperty(
                "$ref"
              )
            ) {
              response.schemas[code] = getComponent(
                response.content[DEFAULT_RESPONSE_TYPE].schema["$ref"],
                spec.components
              );
            }
          });

          // request body allows for more details to be added to the definitions from the apigateway open api spec
          // therefore we rely on the request body. however, we have request parameters AND request body and those don't match properly
          // because of that we rely on the request body values to overwrite the request params
          // we need the request params in the api-gateway-public to ensure that we have the correct values for the api gateway method integrations
          const requestBody = item[method].requestBody;
          if(requestBody) {
            if (!requestBody.schema) {
              requestBody.schema = {};
            }

            if (requestBody.content?.hasOwnProperty(DEFAULT_RESPONSE_TYPE) &&
                requestBody.content[DEFAULT_RESPONSE_TYPE]?.schema.hasOwnProperty("$ref")) {
              requestBody.schema = getComponent(requestBody.content[DEFAULT_RESPONSE_TYPE].schema["$ref"], spec.components);
            }
            Object.keys(requestBody.schema).forEach((key) => {
              const content = requestBody.schema[key];
              
              const keyIdx = item[method].parameters.findIndex((param) => param.name === key);
              if (keyIdx !== -1) {
                item[method].parameters.splice(keyIdx, 1);
              }

              item[method].parameters.push({in: content.title, name: key, description: content.description, schema: {type: content.type}, required: content.required});
            });
          }
          acc.push({
            summary: item.summary,
            description: item.description,
            path,
            method: method.toUpperCase(),
            operation: item[method],
          });
        }
      });
      return acc;
    },
    []
  );

  items.sort((a, b) => (a.path < b.path ? -1 : 1));

  return {
    title: spec.info.title,
    description: spec.info.description,
    version: spec.info.version,
    items: items,
  };
};

const getComponent = (path: string, components: OpenAPIComponents) => {
  const [, , , schemaType] = path.split("/");

  return convertSchema((components.schemas as any)[schemaType].properties, (components.schemas as any)[schemaType].required);
};

const convertSchema = (schema: any, requiredKeys: any) => {
  const res = {};
  Object.keys(schema).forEach((key) => {
    const item = schema[key];
    if (key !== "type" && item.hasOwnProperty("type")) {
      if (item.type === "array") {
        res[key] = [convertSchema(item.items.properties, requiredKeys)];
      } else {
        res[key] = {type: item.type, description: item.description, required: requiredKeys?.includes(key) || false, title: item.title};
      }
    }
  });

  return res;
};
