import React from "react";
import { OpenAPIParameter } from "redoc/typings/types";

type ParamSecondaryDetailsProps = {
  param: OpenAPIParameter;
};

export const ParamSecondaryDetails: React.FC<ParamSecondaryDetailsProps> = ({
  param,
}) => (
  <>
    {param.required && (
      <>
        <span>required</span> -{" "}
      </>
    )}
    {(param.schema as any).type}
    {param.description && (
        <>
          <br/>
          <span>{param.description}</span>
        </>
    )}
  </>
);
