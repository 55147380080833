import React from "react";
import styled from "styled-components";
import {PageTitle, PageDescription, PageParagraph, PageLineItem} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";

const StyledParagraph = styled.p`
    font: 18px "Avenir Next";
    margin-top: 8px;
`;

export const Introduction = () => {
    return (
        <div>
            <PageTitle>Introduction</PageTitle>

            <PageDescription>
                Welcome to the MarginEdge public API developer resources! We’ve compiled a wealth of resources and
                documentation of the MarginEdge public APIs that are primarily intended for software developers who are
                building an integration with MarginEdge.
            </PageDescription>
            <PageParagraph>
                The MarginEdge public APIs currently provide the ability to programmatically retrieve invoice and
                product data from MarginEdge from restaurants you’re authorized to access. Common use cases include:
            </PageParagraph>
            <ul>
                <PageLineItem>
                    Multi-unit restaurant companies with software development staff and homegrown reporting tools
                    who would like to do more custom reporting from their purchasing data.
                </PageLineItem>
                <PageLineItem>
                    Accounting groups who would like to provide more value to their restaurant customers with access
                    to additional data.
                </PageLineItem>
                <PageLineItem>
                    Business intelligence tool providers who are contracting with MarginEdge customers to provide
                    data services.
                </PageLineItem>
            </ul>
            <StyledParagraph>
                We encourage you to peruse the resources available here and contact us at <a
                href='mailto:help@marginedge.com'>help@marginedge.com</a> with any questions or specific API issues as
                you begin development of your integration.
            </StyledParagraph>
        </div>
    );
};
