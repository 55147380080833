import React, {useMemo} from 'react';
import {tableContents} from "./tableContents";
import '../../assets/css/table.css';
import {
    PageDescription,
    PageLineItem,
    PageTitle
} from "../../components/DeveloperGuidePageComponents/DeveloperGuidePageComponents";
import styled from "styled-components";

const StyledUnOrderedList = styled.ul`
    padding: 16px 0 24px 40px;
    margin: 0 0 0 0;
`;
export const ApiOverview = () => {
    const data = useMemo(() => {
        return tableContents.map((content) => {
            return {
                ...content,
                endPoint: content.endPoint.replace(/<code>/g, '<code class="bordered-code">')
            };
        });
    }, []);

    const tableRows = useMemo(() => {
        return data.map((dt, index) => {
            return (
                <tr key={index}>
                    <td>{dt.name}</td>
                    <td dangerouslySetInnerHTML={{__html: dt.endPoint}}></td>
                    <td>{dt.description}</td>
                </tr>
            );
        });
    }, [data]);

    const typesOfData = useMemo(() => {
        return ['Raw invoice data', 'Product data', 'Categories', 'Vendors', 'Vendor items'];
    }, []);

    const typesOfDataList = useMemo(() => {
        return (
            <StyledUnOrderedList>
                {typesOfData.map((data, index) => {
                    return (
                        <PageLineItem key={index}>{data}</PageLineItem>
                    );
                })}
            </StyledUnOrderedList>
        )
    }, [typesOfData]);

    return (
        <div>
            <PageTitle>API Overview</PageTitle>
            <PageDescription>
                The endpoints currently available are described in the table below. Currently the MarginEdge public API
                allows read access to the following types of data:
            </PageDescription>
            {typesOfDataList}
            <table>
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Endpoint</th>
                        <th>Description</th>
                    </tr>
                    {tableRows}
                </tbody>
            </table>
        </div>
    )
};