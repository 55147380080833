import { List, ListItem, ListItemText } from "@mui/material";
import React, { useMemo } from "react";
import { OpenAPIParameter } from "redoc/typings/types";
import { PortalSpecItem } from "types";
import { ParamSecondaryDetails } from "./ParamSecondaryDetails";
import { SubSectionHeader } from "./SubSectionHeader";

type HeadersSectionProps = {
  item: PortalSpecItem;
};

export const HeadersSection: React.FC<HeadersSectionProps> = ({ item }) => {
  const headers: OpenAPIParameter[] = useMemo(() => {
    return item.operation.parameters.filter(
      (param) => (param as any).in === "header" && (param as any).name !== "host"
    ) as OpenAPIParameter[];
  }, [item.operation.parameters]);

  if (!headers.length) {
    return null;
  }

  return (
    <div>
      <SubSectionHeader title="Headers" />

      <List>
        {headers.map((header) => {
          return (
            <ListItem key={header.name}>
              <ListItemText
                primary={header.name}
                secondary={<ParamSecondaryDetails param={header} />}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
