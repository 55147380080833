import { ListItem, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

const StyledPrimaryBase = styled.div`
  width: 100%;
  height: 45px;
  align-items: center;
  padding-left: 1rem;
  margin-top: 2rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledPrimaryActive = styled(StyledPrimaryBase)`
  background-color: #0072ce;
  color: #fff;

  span {
    font-weight: 700 !important;
    font-family: "Avenir Next Bold", sans-serif;
  }
`;

const StyledPrimary = styled(StyledPrimaryBase)`
  color: #57595b;

  a {
    text-decoration: none !important;
    color: #57595b !important;
  }

  span {
    font-weight: 700 !important;
    font-family: "Avenir Next Bold", sans-serif;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledIconBase = styled.i`
  font-size: 0.7rem;
  margin-right: 1.5rem;
`;

const StyledIconUp = styled(StyledIconBase)`
  color: #fff;
`;

const StyledIconDown = styled(StyledIconBase)`
  color: #57595b;
`;

type PrimaryNavItemProps = {
  title: string;
  active?: boolean;
  path: string;
};

export const PrimaryNavItem = ({
  active,
  path,
  title,
}: PrimaryNavItemProps) => {
  return (
    <ListItem style={{ margin: 0, padding: 0 }}>
      {active ? (
        <StyledPrimaryActive>
          <ListItemText primary={title} />
          <StyledIconUp className="fa-solid fa-chevron-up" />
        </StyledPrimaryActive>
      ) : (
        <StyledPrimary>
          <StyledLink to={path}>
            <ListItemText primary={title} />
            <StyledIconDown className="fa-solid fa-chevron-down" />
          </StyledLink>
        </StyledPrimary>
      )}
    </ListItem>
  );
};
